// export const icons_general = {
// 	'wave': 'icon-wave',
// 	'letter-heart': 'icon-letter-heart',
// 	'eyes': 'icon-eyes',
// 	'letter-box': 'icon-letter-box',
// }

export const icons = {
	'wave': 'icon-wave',
	'letter-heart': 'icon-letter-heart',
	'eyes': 'icon-eyes',
	'letter-box': 'icon-letter-box',
	'letter-blitz': 'icon-letter-blitz',
	'board': 'icon-board',
	'pen': 'icon-pen',
	'smiley-laugh': 'icon-smiley-laugh',
	'megaphone': 'icon-megaphone',
	'smiley-love': 'icon-smiley-love',
	'family': 'icon-family',
	'alert': 'icon-alert',
	'new-funds': 'icon-new-funds',
}

/**
 * Available options as Notification Types
 */
export const notification_types = ['SUBMISSION', 'NEW_RSVP', 'PREVIEW_READY', 'FUNDS']

/**
 * Available options as Content Types
 */
export const content_types = ['EVENT_MEDIA', 'RSVP', 'RENDER_JOB', 'EVENT', 'CASH_CONTRIBUTION', 'TREE_CONTRIBUTION']
